import jQuery from 'jquery/dist/jquery';
import * as bootstrap from 'bootstrap'
import Utils from '../../../../utils'
import ReportEntity from "../../../report/entity";
import Quill from 'quill'


export default class TicketShowReports {
    private parent: any;
    private toastr: any;
    private accord: HTMLElement;
    private commentEditor: any;

    constructor(parent: any) {
        this.parent = parent
        this.toastr = parent.toastr
        this.accord = document.querySelector("#ticketReportsAccordionChilds") as HTMLElement;
        if (this.accord) {
            this.bindListeners();
        }
    }

    bindListeners() {
        this.commentEditor = new Quill('#report_description', {
            bounds: '#report_description',
            placeholder: Utils.translate("ticket.chat.placeholder"),
            modules: {
                toolbar: '#snow-report-toolbar'
            },
            theme: 'snow'
        });
        this.accord.addEventListener("click", async (e) => {
            const target = e.target as HTMLElement;
            if (target && target.classList.contains("ticketReportsAccordionEditEnable")) {
                const uuid = (target.closest(".accordion-collapse") as HTMLElement).getAttribute("data-uuid");
                await Utils.entity.destroy(uuid, 'reports');
                await this.parent.getEntity();
            }
        });

        (document.querySelector("#addNewTicketReport") as HTMLFormElement).addEventListener("submit", async (e) => {
            e.preventDefault();
            const form = document.querySelector("#addNewTicketReport") as HTMLFormElement;
            if (form.checkValidity()) {
                const desc = this.commentEditor.root.innerHTML

                const arrivalFrom = (form.querySelector("#report_arrival_from") as HTMLInputElement).value
                const arrivalTo = (form.querySelector("#report_arrival_to") as HTMLInputElement).value

                const arrivalResourceId = (form.querySelector("#report_arrival_resource_id option:checked") as HTMLInputElement) ? (form.querySelector("#report_arrival_resource_id option:checked") as HTMLInputElement).value : null
                const arrivalDistance = (form.querySelector("#report_arrival_distance") as HTMLInputElement).value

                const returnFrom = (form.querySelector("#report_return_from") as HTMLInputElement).value
                const returnTo = (form.querySelector("#report_return_to") as HTMLInputElement).value
                const returnResourceId = (form.querySelector("#report_return_resource_id option:checked") as HTMLInputElement) ? (form.querySelector("#report_return_resource_id option:checked") as HTMLInputElement).value : null
                const returnDistance = (form.querySelector("#report_return_distance") as HTMLInputElement).value

                const spentFrom = (form.querySelector("#report_spent_from") as HTMLInputElement).value
                const spentTo = (form.querySelector("#report_spent_to") as HTMLInputElement).value


                const calendarAppointmentId = parseInt((form.querySelector("#report_calendar_appointment_id option:checked") as HTMLInputElement).value)
                const files = (form.querySelector("#report_attachments") as HTMLInputElement).files
                const fileNames = []
                if (desc) {
                    const fileData = []
                    if (files) {
                        for (let x = 0; x < files.length; x++) {
                            fileData.push(await Utils.getBase64(files[x]))
                            fileNames.push(files[x].name)
                        }
                    }
                    const btn = (form.querySelector("button.btn-primary")) as HTMLButtonElement;
                    btn.setAttribute("disabled", "disabled");
                    const r = await Utils.entity.upsert({
                        ticket_id: this.parent.id,
                        calendar_appointment_id: calendarAppointmentId === 0 ? null : calendarAppointmentId,
                        description: desc,
                        arrival_from: arrivalFrom,
                        arrival_to: arrivalTo,
                        arrival_resource_id: arrivalResourceId,
                        arrival_distance: arrivalDistance,
                        return_from: returnFrom,
                        return_to: returnTo,
                        return_resource_id: returnResourceId,
                        return_distance: returnDistance,
                        spent_from: spentFrom,
                        spent_to: spentTo,
                        files: fileData,
                        file_names: fileNames
                    }, 'reports')
                    form.reset();
                    await this.parent.getEntity();
                }
            }
        })

        //@ts-ignore
        jQuery("#report_calendar_appointment_id").on("select2:select", async (e: any) => {
            const target = (e.target as HTMLElement).querySelector("option:checked") as HTMLOptionElement;
            //@ts-ignore
            const from = new Date(target.getAttribute("data-from"));
            const fromValue = new Date(from.getTime() + new Date().getTimezoneOffset() * -60 * 1000).toISOString().slice(0, 19);

            //@ts-ignore
            const to = new Date(target.getAttribute("data-to"));
            const toValue = new Date(to.getTime() + new Date().getTimezoneOffset() * -60 * 1000).toISOString().slice(0, 19);

            const d = new Date(from.getTime() + new Date().getTimezoneOffset() * -60 * 1000).toISOString().slice(0, 11);
            (document.querySelector("#report_arrival_from") as HTMLInputElement).value= `${d}00:00:00`;
            (document.querySelector("#report_return_to") as HTMLInputElement).value= `${d}00:00:00`;

            (document.querySelector("#report_spent_from") as HTMLInputElement).value= fromValue;
            (document.querySelector("#report_arrival_to") as HTMLInputElement).value= fromValue;
            (document.querySelector("#report_spent_to") as HTMLInputElement).value= toValue;
            (document.querySelector("#report_return_from") as HTMLInputElement).value= toValue;
            console.log(target, from, to)
        })
    }

    update(data: any) {
        const calendarAppointmentSelect = document.querySelector("#report_calendar_appointment_id") as HTMLSelectElement;
        if (calendarAppointmentSelect) {
            calendarAppointmentSelect.innerHTML = "<option value='0'>Not bound to an appointment</option>";

            data.calendar_appointments.forEach((calendarAppointment: any, i: number) => {
                const html = `<option data-from="${calendarAppointment.from_iso}" data-to="${calendarAppointment.to_iso}" value='${calendarAppointment.uuid}'>${calendarAppointment.from} | ${calendarAppointment.user.name} | ${calendarAppointment.service.workPackageName}</option>`
                calendarAppointmentSelect.innerHTML = calendarAppointmentSelect.innerHTML + html;
            });
        }
        if (this.accord) {
            this.accord.innerHTML = ""
            //@ts-ignore
            data.reports.forEach((report: any, i: number) => {
                let attachmentHtml = 'No attachments available.'
                if (report.attachments.length > 0) {
                    attachmentHtml = ''
                    report.attachments.forEach((attachment: any) => {
                        attachmentHtml += `<a href='${attachment.url}' target="_blank">${attachment.name}</a>`
                    });
                }
                // @ts-ignore
                const arrivalDiff = Math.abs(new Date(Date.parse(report.arrival_to)) - new Date(Date.parse(report.arrival_from)))
                // @ts-ignore
                const workDiff = Math.abs(new Date(Date.parse(report.spent_to)) - new Date(Date.parse(report.spent_from)))
                // @ts-ignore
                const returnDiff = Math.abs(new Date(Date.parse(report.return_to)) - new Date(Date.parse(report.return_from)))
                const html = `<div class="card accordion-item">
                <h2 class="accordion-header">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#ticketReportsAccordionRow${i}" aria-expanded="false" aria-controls="ticketReportsAccordionRowNew">
                   ${report.user.name} at ${report.created_at}
                  </button>
                </h2>
                <div id="ticketReportsAccordionRow${i}" class="accordion-collapse collapse" data-bs-parent="#ticketReportsAccordion" style="" data-uuid="${report.uuid}">
             
                  <div class="accordion-body">
                    <div class="row mb-4">
                        <div class="col-6"> 
                            <h4>Description</h4>
                            ${report.description}
                        </div> 
                        <div class="col-6"> 
                            <h4>Attachments</h4>
                            ${attachmentHtml}
                        </div> 
                    </div>
                    <div class="row mb-4">
                        <div class="col-3"> 
                            <h4>Appointment</h4>
                            ${report.calendar_appointment ? `${report.calendar_appointment.from}` : 'Not bound to an appointment.'}
                        </div> 
                        <div class="col-3"> 
                            <h4>Arrival</h4>
                            ${report.arrival_from} - ${report.arrival_to} (${Math.floor((arrivalDiff/1000)/60)}min)<br />${report.arrival_resource.name ? `${report.arrival_resource.name}, ` : ``}${report.arrival_distance ? `${report.arrival_distance}km` : ``}
                        </div> 
                        <div class="col-3"> 
                            <h4>Work</h4>
                            ${report.spent_from} - ${report.spent_to} (${Math.floor((workDiff/1000)/60)}min)
                        </div> 
                        <div class="col-3"> 
                            <h4>Return</h4>
                            ${report.return_from} - ${report.return_to} (${Math.floor((returnDiff/1000)/60)}min)<br />${report.return_resource.name ? `${report.return_resource.name}, ` : ``}${report.return_distance ? `${report.return_distance}km` : ``}
                        </div> 
                    </div>
                    <div class="row">
                        <div class="col-12">
                             <button class="btn btn-danger ticketReportsAccordionEditEnable">Delete</button>
                        </div>
                    </div>
                  </div>
                </div>
              </div>`
                this.accord.innerHTML = html + this.accord.innerHTML;
            })
        }
    }
}